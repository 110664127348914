// Angular
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Components
import { AddressBookComponent,
  AddressBookSettings,
  PaymentIdLabel,
  DialogReturnData
} from './../../address-book/crypto-address-book/address-book.component';
import {
  IConfirmDialogData,
  ConfirmDialogComponent,
  DialogTypes,
} from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { SnackbarMessageComponent } from 'src/app/shared/snackbar-message/snackbar-message';
import { TwoFactorComponent } from 'src/app/settings/two-factor/two-factor.component';
// Interfaces
import { AddressBook, AddressStatus } from 'src/app/core/interfaces/addressbook';
import { BankAddressBook, BankAddressBooks, BankAddressStatus, BankAddressVerified, BankLimits } from 'src/app/core/interfaces/bankAddressbook';
import { BankAddressBookCloseData, BankAddressBookComponent, BankAddressBookSettings } from '../../address-book/bank-address-book/bank-address-book.component';
// Libraries
import * as Big from 'big-js';
import * as Bolt11 from 'light-bolt11-decoder';
import { Observable, Subject, defer, timer, merge } from 'rxjs';
import { startWith, take, map, tap, debounceTime, distinctUntilChanged, takeUntil, skipWhile, mergeMap } from 'rxjs/operators';
// Services / Util
import { BalancesService } from '../../balances.service';
import { I18nTranslationService } from '../../../core/services/i18n-translation.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { SessionStorageService } from './../../../core/services/session-storage.service';
import { StoreService } from '../../../core/services/store.service';
import { MarketsService } from 'src/app/markets/markets.service';
import { UtilHelper } from 'src/app/core/helpers/util-helper';
// Store
import { Store } from '@ngxs/store';
import { AddressBookStore, GetCryptoAddressBook,
  GetFiatAddressBook
} from '../../address-book/state/address-book.state';
import { FundPinComponent } from 'src/app/settings/fund-pin/fund-pin.component';
import { FundPinStatus } from 'src/app/core/interfaces/fund-pin';
import { SettingsService } from 'src/app/settings/settings.service';
import { CoinNetwork } from 'src/app/core/interfaces/coin-network';
import { environment } from 'src/environments/environment';
import { CurrencyManagementComponent } from '../currency.management.component';
import { DomainDialogComponent } from 'src/app/shared/domain-dialog/domain-dialog.component';


interface VideoInput {
  found: boolean;
  hasPermission: boolean;
}

@Component({
  selector: 'app-withdrawal',
  templateUrl: './withdrawal.component.html',
  styleUrls: ['./withdrawal.component.scss']
})
export class WithdrawalComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line:no-input-rename
  @Input('coin') coinCode: string = 'ZAR';
  // tslint:disable-next-line:no-input-rename
  @Input('type') coinType: string = 'fiat';
  @Input('selectedCoinNetwork')
  set setDropdown(value: CoinNetwork) {
    if (value && value.id !== this.selectedCoinNetwork?.id) {
      this.selectedCoinNetwork = value;
      if (value.id && this.cryptoWithdrawal && this.fiatWithdrawal) {
        this.applyCoinNetwork();
      }
    }
  }
  selectedCoinNetwork: CoinNetwork = null;
  @Input() coinNetworks: CoinNetwork[] = [];
  @Input() dialogRef: MatDialogRef<CurrencyManagementComponent>;
  @Output() networkChange: EventEmitter<any> = new EventEmitter();

  FundPinStatus: typeof FundPinStatus = FundPinStatus;
  private _timeout$: Subject<void> = new Subject();

  currencyInfo: any = {
    'balance_available': '0',
    'ieo_coin': '-1',
    'withdraw': {
      'withdrawFee': '0.000000',
      'withdrawData': {
        'totalCoin': 0,
        'limitCoin': 0,
        'totalCoinMonth': 0,
        'limitCoinMonth': 0,
        'totalCoinFiatEquivalent': '0.00000000',
        'limitCoinFiatEquivalent': '0.00000000',
        'totalCoinFiatEquivalentMonth': '0.00000000',
        'limitCoinFiatEquivalentMonth': '0.00000000'
      },
      'verificationLevel': 1,
      'withdrawLimits': {
        'max_withdraw': '0.00000000',
        'min_withdraw': '0.00000000'
      },
      'has_coin_networks': 'false',
    },
    balance: { balance_available: '0' },

    is2faenabled: false,
    isFundpinEnabled: 0
  };

  subs: any = [];

  checkAccountTimeout: any;

  fiatWithdrawal: FormGroup;
  cryptoWithdrawal: FormGroup;
  bankLink: FormGroup;

  limitFiat: string;
  limitFiatMonth: string;
  limitCoin: number;
  limitCoinMonth: number;
  totalFiat: string;
  totalFiatMonth: string;
  totalCoin: number;
  totalCoinMonth: number;

  regexTelephone: RegExp = /^\d{10}$/;
  regexWallet: RegExp = /^[a-zA-HJ-NP-Z0-9@\+\-\.\:]+$/;

  bankNames: any[] = [
    'ABSA',
    'Standard Bank',
    'First National Bank',
    'Netbank',
    'Capitec'
  ];

  accountTypes: any[] = [
    'Cheque/Current',
    'Savings'
  ];

  coinNetworkOptions: CoinNetwork[] = [];

  linkBankSelected: string = this.bankNames[0];
  linkAccountNumber: string;
  linkAccountType: string = this.accountTypes[0];
  linkBranchCode: string;

  linkedAccount: boolean = false;

  showDomainPrompt: boolean = true;
  allowWithdrawal: boolean = true;
  disabledFromSettings: boolean = false;
  disabledFromTime: boolean = false;

  minAmount: any = 10;
  maxAmount: any = 0;
  withdrawalAmount: any;
  withdrawalFee: number;
  netAmount: any;
  withdrawalAddress: string;
  withdrawalTwofa: string;
  paymentId: string = '';
  invoiceMemo: string = '';
  invoiceReference: string = '';

  linkedBankName: string;
  linkedAccountNumber: string;
  linkedBankStatus: string;
  linkedAccountTypeName: string;

  isTransfer: boolean;
  isEmailorNumber: boolean;
  confirmLightning: boolean = true;
  lightningDailyLimit: number;
  lightningOverDailyLimit: boolean = true;

  emailRegex: RegExp = new RegExp(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[^\s@]{2,}$/);
  numberRegex: RegExp = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/);

  showNotification: boolean = true;
  verificationLevel: number;

  parent: any;

  translatedWords: object = {
    'Verification Pending': this.i18n('Verification Pending'),
    'Verified': this.i18n('Verified'),
    'Verification Failed': this.i18n('Verification Failed'),
  };

  videoInput: VideoInput = {
    found: false,
    hasPermission: null
  };

  scanQR: boolean = false;

  addressBookOptions: AddressBook[];
  addressBookfilteredOptions: Observable<AddressBook[]>;
  untrustedAddress: boolean = true;
  identifiedNewAddress: boolean = false;
  selectedFromBook: boolean = false;
  selectedAddress: AddressBook;

  bankAddressBookOptions: BankAddressBook[];
  bankAddressBookfilteredOptions: BankAddressBook[];
  selectedBank: BankAddressBook = null;
  zarLimits: BankLimits;
  // Used to reference enum on html
  BankAddressVerified: typeof BankAddressVerified = BankAddressVerified;
  BankAddressStatus: typeof BankAddressStatus = BankAddressStatus;
  AddressStatus: typeof AddressStatus = AddressStatus;

  fastClearing: boolean = false;
  fastClearingError: boolean = false;
  fastClearingTimes: Array<number> = [null, null];
  private fastClearingTimer: Observable<boolean> = defer(() => this.coinCode !== 'ZAR' ? null :
    timer(0, 1000).pipe(
      startWith(this.fastClearingError),
      skipWhile(() => !((+this.fastClearingTimes[0] >= 0) && (+this.fastClearingTimes[1] > 0))),
      map(() => {
        const currentUTCHour = (new Date()).getUTCHours();
        return (currentUTCHour >= this.fastClearingTimes[0]) && (currentUTCHour < this.fastClearingTimes[1]);
      }),
      distinctUntilChanged(),
      tap(isValid => this.fastClearingError = !isValid),
      takeUntil(this._timeout$)
    )
  );

  private _addressBookfilter(value: string): AddressBook[] {
    if (!!value) {
      const filterValue = value.toLowerCase();
      return this.addressBookOptions.filter(address =>
        address.name.toLowerCase().indexOf(filterValue) === 0 ||
        address.address.toLowerCase().indexOf(filterValue) === 0
      );
    } else {
      return this.addressBookOptions;
    }
  }

  constructor(
    public balancesService: BalancesService,
    private settingsService: SettingsService,
    private sessionStorage: SessionStorageService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private store: StoreService,
    private i18n: I18n,
    private translateService: I18nTranslationService,
    public snackbar: MatSnackBar,
    private _addressStore: Store,
    private marketsService: MarketsService
  ) { }

  ngOnInit() {
    this.cryptoWithdrawal = this.formBuilder.group({
      amount:  ['', Validators.required],
      fee: [''],
      netAmount: ['', Validators.required],
      coin_network: [{}],
      invoiceAddress: [''],
      fundPin: ['', Validators.required],
      withdrawal: ['', Validators.required],
      twofa: [''],
      paymentID: ['', Validators.required],
      paymentIdNotRequired: [''],
      storeAddress: [false]
    });

    this.fiatWithdrawal = this.formBuilder.group({
      bankSelection: ['', Validators.required],
      amount:  ['', Validators.required],
      fee: [''],
      netAmount: ['', Validators.required],
      coin_network: [{}],
      fundPin: ['', Validators.required],
      twofa: [''],
      bank: [''],
      paymentID: [''],
      fastClearing: ['']
    });

    this.bankLink = this.formBuilder.group({
      bankSelection: ['', Validators.required],
      accountNumber: ['', Validators.required],
      accountType: ['', Validators.required],
      branchCode: ['', Validators.required]
    });

    if (this.coinNetworks.length > 0 && !this.selectedCoinNetwork) {
      this.snackbar.open(
        this.translateService.translateResponse('The network for this coin is currently unavailable.'), this.i18n('Close'), {duration: 2000}
      );
      this.dialogRef.close();
    }

    this.coinNetworkOptions = this.coinNetworks; // create independant display list

    if (this.coinCode === 'ZAR') {
      this._addressStore.select(AddressBookStore.GetFiatAddressBook(this.coinCode))
        .pipe(takeUntil(this._timeout$))
        .subscribe((value: BankAddressBooks) => {
          if (!!value && value.addresses.length > 0) {
            this.linkedAccount = true;
            this.bankAddressBookOptions = value.addresses;
            this.zarLimits = JSON.parse(JSON.stringify(value.limits));
          } else {
            this.linkedAccount = false;
          }
          this.bankAddressBookfilter();
        });

      this._addressStore.dispatch(new GetFiatAddressBook(this.coinCode))
        .pipe(takeUntil(this._timeout$))
        .subscribe();

      this.fiatWithdrawal.controls.coin_network.setValue(this.selectedCoinNetwork);
    } else {
      merge(
        this._addressStore.dispatch(new GetCryptoAddressBook(this.coinCode)).pipe(
          mergeMap(() => this._addressStore.select(AddressBookStore.GetCryptoAddressBook(this.coinCode))),
          distinctUntilChanged(),
          tap((value) => {
            this.addressBookOptions = value;
          }),
          takeUntil(this._timeout$)
        ),

        this.cryptoWithdrawal.controls.paymentIdNotRequired.valueChanges.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          tap((value) => {
            this.setPaymentIdValidators();
          }),
          takeUntil(this._timeout$)
        ),

        this.cryptoWithdrawal.controls.invoiceAddress.valueChanges.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          tap(() => this.invoiceChange()),
          takeUntil(this._timeout$)
        ),

        this.cryptoWithdrawal.controls.paymentID.valueChanges.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          skipWhile(value => value === ''),
          tap((value) => {
            this.paymentId = this.cryptoWithdrawal.controls.paymentID.value;
            if (!!this.paymentId) {
              this.userTyped(this.paymentId);
            }
          }),
          takeUntil(this._timeout$)
        )
      ).subscribe();

      this.addressBookfilteredOptions = this.cryptoWithdrawal.controls.withdrawal.valueChanges.pipe(
        startWith(''),
        debounceTime(200),
        distinctUntilChanged(),
        map(value => this._addressBookfilter(value)),
        tap(() => {
          if (!!this.cryptoWithdrawal.controls.withdrawal.value) {
            if (this.addressBookOptions.length === 1) {
              this.checkForTrustedAddress();
            }
          } else {
            this.untrustedAddress = true;
          }
        })
      );

      this.cryptoWithdrawal.controls.coin_network.setValue(this.selectedCoinNetwork);
    }

    this.subs.push(this.store.subscribe('balances').subscribe((response: any) => {
      this.getBalance();
    }));

    this.subs.push(this.store.subscribe('settings').subscribe((response) => {
      if (!response.refresh) {
        this.showNotification = !response.data.confirmations ? true : response.data.confirmations === '1';
      }
      // check withdrawal settings
      if (response.data.withdrawSettings) {
        const withdrawSettings = response.data.withdrawSettings;
        if (withdrawSettings['enabled']) {
          if ((withdrawSettings.enabled.time) &&
            (+(withdrawSettings.enabled.time) + 86400 > Date.now() / 1000)) {
            this.disabledFromTime = true;
          } else if (typeof withdrawSettings.enabled.value ===  'boolean') {
            if (!withdrawSettings.enabled.value) {
              this.disabledFromSettings = true;
            }
          }
        }

        if (withdrawSettings[this.coinCode]) {
          if (withdrawSettings[this.coinCode].time &&
              withdrawSettings[this.coinCode].time + 86400 > Date.now() / 1000) {
            this.disabledFromTime = true;
          }
          if (typeof withdrawSettings[this.coinCode].value === 'boolean') {
            if (!withdrawSettings[this.coinCode].value) {
              this.disabledFromSettings = true; // avoid setting back to false
            }
          }
        }

        if (this.coinCode.toLowerCase() === 'btc') {
          let dailyLimit = 0;
          const lightningSettings = response.data.lightningSettings;
          if (
            lightningSettings['confirmLightning'] &&
            typeof lightningSettings['confirmLightning']?.value === 'boolean'
          ) {
            dailyLimit = lightningSettings['dailyLimit']?.value;
            this.confirmLightning = !!lightningSettings['confirmLightning']?.value;
          }

          if (!this.confirmLightning) {
            this.lightningDailyLimit = dailyLimit;
          }
        }
      }
      if (response.data.show_domain_prompt === 0 || response.data.domains_available === false) {
        this.showDomainPrompt = false;
        this.sessionStorage.store('SHOW_DOMAIN_PROMPT', 0);
      }
    }));
    // this.getBanks();
    // this.getBankAccountTypes();

    this.currencyInfo = {
      'balance_available': '0',
      'withdraw': {
        'withdrawFee': '0.000000',
        'withdrawData': {
          'totalCoin': 0,
          'limitCoin': 0,
          'totalCoinMonth': 0,
          'limitCoinMonth': 0,
          'totalCoinFiatEquivalent': '0.00000000',
          'limitCoinFiatEquivalent': '0.00000000',
          'totalCoinFiatEquivalentMonth': '0.00000000',
          'limitCoinFiatEquivalentMonth': '0.00000000'
        },
        'verificationLevel': 1,
        'withdrawLimits': {
          'max_withdraw': '0.00000000',
          'min_withdraw': '0.00000000'
        },
        'totalLightningWithdrawnToday': '0'
      },
      balance: { balance_available: '0' },

      cryptoLevelRestricted: false,
      is2faenabled: true,
      isFundpinEnabled: 0
    };

    let level: any = this.sessionStorage.get('PROFILE_VERIFICATION_LEVEL');
    if (level === '') {
      level = 0;
    } else {
      level = Number(level);
    }

    this.verificationLevel = level;
    if (this.sessionStorage.get('SHOW_DOMAIN_PROMPT') === 0) {
      this.showDomainPrompt = false;
    }

    this.setupFormGroup();

    this.subs.push(this.store.subscribe('withdrawal').subscribe((response) => {
      if ( response.refresh ) {
        this.getBalance();
      }
    }));

    // Determine if video input is available
    navigator.mediaDevices.enumerateDevices().then((stream) => {
      this.videoInput.found = !!stream.find(element => element.kind === 'videoinput');
    });
  }

  ngOnDestroy() {
    this.subs.forEach(sub => !!sub && sub.unsubscribe());
    this._timeout$.next();
    this._timeout$.complete();
  }

  applyCoinNetwork() {
    if (this.cryptoWithdrawal) {
      this.cryptoWithdrawal.controls.coin_network.setValue(this.selectedCoinNetwork);
    }
    if (this.fiatWithdrawal) {
      this.fiatWithdrawal.controls.coin_network.setValue(this.selectedCoinNetwork);
    }
    this.setupFormGroup();
    this.networkChange.emit(this.selectedCoinNetwork);

    if (this.isLightningNetwork()) {
      this.cryptoWithdrawal.controls.withdrawal.disable();
      this.cryptoWithdrawal.controls.invoiceAddress.enable();
      this.isLightningOverLimit(this.withdrawalAmount);
    } else {
      this.cryptoWithdrawal.controls.withdrawal.enable();
      this.cryptoWithdrawal.controls.invoiceAddress.disable();
    }
    this.cryptoWithdrawal.controls.withdrawal.updateValueAndValidity();
    this.cryptoWithdrawal.controls.invoiceAddress.updateValueAndValidity();
  }

  changeNetwork(event: any) {
    if (event.value.id) { // skip invalid networks
      this.selectedCoinNetwork = event.value;
      this.applyCoinNetwork();
    }
  }

  compareDate() {
    // checks if it is before the 30th of june 2021 @15h00
    return (new Date().getTime()) <= (new Date('2021-06-30 15:00:00 UTC+02').getTime());
  }

  getBalance() {
    this.store.getBalance(this.coinCode).subscribe((data: any) => {
      if (data.response === 'success') {
        this.currencyInfo = data.data;
        this.getLevelLimits();

        this.currencyInfo.linked_account_verify_status = !this.currencyInfo.linked_account_verify_status ? '0' :
          this.currencyInfo.linked_account_verify_status.toString();
        if (this.coinCode === 'ZAR') {
          this.fastClearingTimes[0] = Number(this.currencyInfo.withdraw.fastClearing.start);
          this.fastClearingTimes[1] = Number(this.currencyInfo.withdraw.fastClearing.end);
          if (!!this.selectedBank) {
            this.fiatWithdrawal.controls.bankSelection.setValue(this.selectedBank.name);
            this.setBankMaxLimits();
          } else if (!!this.currencyInfo.linked_account && !!this.bankAddressBookOptions) {
            // Remove all '*' from linked_account string
            const temp = this.currencyInfo.linked_account.replace(/\*/gi, '');
            // Create regex to find complete account number
            const addressRegex = new RegExp(temp, 'g');
            // Loop through bank addresses and find address that matches the temp value
            this.bankAddressBookOptions.forEach((address) => {
              const idx = address.accountNumber.search(addressRegex);
              if (idx > -1) {
                this.fiatWithdrawal.controls.bankSelection.setValue(address.name);
              }
            });
          }
          this.bankAddressBookfilter(true);
        }
        this.setupFormGroup();
      }
    }, () => {});
  }

  dismissDomain() {
    this.showDomainPrompt = false;

    const data: any = {
      show_domain_prompt: 0
    };

    this.settingsService.updateSettings(data)
        .subscribe((response: any) => {
          if (response.response === 'success') {
            this.sessionStorage.store('SHOW_DOMAIN_PROMPT', 0);
          } else if (response.response === 'failure') {
            this.snackbar.open(
              this.translateService.translateResponse(response.reason), this.i18n('Close'), {duration: 2000}
            );
          }
        });
  }

  openDomainDialog() {
    let searchTerm;

    let username: string = this.sessionStorage.get('PROFILE_EMAIL');
    if (username && username.indexOf('@') > 0) {
      // user email set, get searchterm from email
      searchTerm = username.split('@')[0];
    } else {
      // try to get searchterm from user's first name
      username = this.sessionStorage.get('PROFILE_FIRST_NAME');
      if (username) {
        searchTerm = username;
      }
    }

    this.dialog.open(DomainDialogComponent,
      { width: '500px', data: { searchTerm: searchTerm } });
  }

  setupFormGroup() {
    this.calcMaxAmount();
    if (this.fastClearing) {
      this.minAmount = Number(this.currencyInfo.withdraw.fastClearing.fee) + 1;
    } else {
      this.minAmount = this.selectedCoinNetwork && this.selectedCoinNetwork.min_withdraw ?
        Number(this.selectedCoinNetwork.min_withdraw) : Number(this.currencyInfo.withdraw.withdrawLimits.min_withdraw);
    }
    let max = this.maxAmount;
    if (this.maxAmount !== 'unlimited') {
      max = (this.selectedCoinNetwork && this.selectedCoinNetwork.max_withdraw) ?
        Number(this.selectedCoinNetwork.max_withdraw) : Number(this.currencyInfo.withdraw.withdrawLimits.max_withdraw);

    }
    const ba = Number(this.currencyInfo.balance_available);

    if (max !== 'unlimited' && max > ba) {
      max = ba;
    }

    this.isEmailorNumber =
      this.emailRegex.test(this.withdrawalAddress) ||
      this.numberRegex.test(this.withdrawalAddress);

    if (this.isTransfer) {
      this.withdrawalFee = 0;
    } else {
      this.withdrawalFee = (this.selectedCoinNetwork && this.selectedCoinNetwork.withdraw_fee) ?
        Number(this.selectedCoinNetwork.withdraw_fee) : Number(this.currencyInfo.withdraw.withdrawFee);
    }

    if (this.fastClearing) {
      this.withdrawalFee = this.currencyInfo.withdraw.fastClearing.fee;
    }

    this.cryptoWithdrawal.controls.fee.disable();

    this.cryptoWithdrawal.controls.amount.setValidators(null);
    this.cryptoWithdrawal.controls.amount.updateValueAndValidity();

    if (max !== 'unlimited') {
      this.cryptoWithdrawal.controls.amount.setValidators([
        Validators.required,
        Validators.min(this.minAmount),
        Validators.max(Number(max))
      ]);
    } else {
      this.cryptoWithdrawal.controls.amount.setValidators([
        Validators.required,
        Validators.min(this.minAmount)
      ]);
    }

    this.cryptoWithdrawal.controls.amount.updateValueAndValidity();

    this.cryptoWithdrawal.controls.netAmount.setValidators(null);
    this.cryptoWithdrawal.controls.netAmount.updateValueAndValidity();

    this.cryptoWithdrawal.controls.netAmount.setValidators([
      /*Validators.required,*/
      Validators.min(0),
    /*Validators.max(max - this.withdrawalFee)*/]);

    this.cryptoWithdrawal.controls.netAmount.updateValueAndValidity();

    this.fiatWithdrawal.controls.amount.setValidators(null);
    this.fiatWithdrawal.controls.amount.updateValueAndValidity();

    if (this.coinCode === 'ZAR') {
      this.setBankMaxLimits();
    }

    this.fiatWithdrawal.controls.netAmount.setValidators(null);
    this.fiatWithdrawal.controls.netAmount.updateValueAndValidity();

    this.fiatWithdrawal.controls.netAmount.setValidators([
      /*Validators.required,*/
      Validators.min(0),
    /*Validators.max(max - this.withdrawalFee)*/]);

    this.cryptoWithdrawal.controls.netAmount.updateValueAndValidity();

    this.fiatWithdrawal.controls.fee.disable();

    if (this.currencyInfo.is2faenabled) {
      if (!this.isLightningNetwork() || (this.isLightningNetwork() && this.confirmLightning)) {
        this.cryptoWithdrawal.controls.twofa.setValidators([
          Validators.required
        ]);
      }

      /**
       * We only need this to display errors for when the user first lands on the page,
       * and tries to click process withdrawal, without entering anything in the account input
       */
      this.fiatWithdrawal.controls.twofa.setValidators([
        Validators.required
      ]);
    }

    if (!!this.currencyInfo.cryptonote_address && !this.isEmailorNumber) {
      this.cryptoWithdrawal.controls.paymentID.setValidators([
        Validators.required
      ]);
      if (!!this.currencyInfo.payment_memo_label_validation) {
        this.cryptoWithdrawal.controls.paymentID.setValidators([
          Validators.required,
          Validators.pattern(this.currencyInfo.payment_memo_label_validation)
        ]);
      }
      this.fiatWithdrawal.controls.paymentID.setValidators([
        Validators.required
      ]);
      this.cryptoWithdrawal.controls.paymentID.updateValueAndValidity();
      this.fiatWithdrawal.controls.paymentID.updateValueAndValidity();
    } else {
      this.cryptoWithdrawal.controls.paymentID.setValidators(null);
      this.fiatWithdrawal.controls.paymentID.setValidators(null);
      this.cryptoWithdrawal.controls.paymentID.updateValueAndValidity();
      this.fiatWithdrawal.controls.paymentID.updateValueAndValidity();
    }
    if (this.coinNetworks.length > 0) {
      this.cryptoWithdrawal.controls.coin_network.setValidators(Validators.required);
      this.fiatWithdrawal.controls.coin_network.setValidators(Validators.required);
    }
    this.amountChange();
  }

  getLevelLimits() {
    this.limitCoin = this.currencyInfo.withdraw.withdrawData.limitCoin;
    this.limitCoinMonth = this.currencyInfo.withdraw.withdrawData.limitCoinMonth;
    this.totalCoin = this.currencyInfo.withdraw.withdrawData.totalCoin;
    this.totalCoinMonth = this.currencyInfo.withdraw.withdrawData.totalCoinMonth;
    this.limitFiat = this.currencyInfo.withdraw.withdrawData.limitCoinFiatEquivalent;
    this.limitFiatMonth = this.currencyInfo.withdraw.withdrawData.limitCoinFiatEquivalentMonth;
    this.totalFiat = this.currencyInfo.withdraw.withdrawData.totalCoinFiatEquivalent;
    this.totalFiatMonth = this.currencyInfo.withdraw.withdrawData.totalCoinFiatEquivalentMonth;
  }

  netAmountChange() {
    if (!!this.netAmount) {
      const decimalSpaces = (this.coinCode.toLowerCase() === 'zar') ? 2 : 8;
      const ten = new Big(10);
      const multiplier = ten.pow(decimalSpaces);

      if (UtilHelper.checkDecimalSpacesLimit(this.netAmount, decimalSpaces)) {
        this.netAmount = new Big(Number(this.netAmount));
        this.netAmount = new Big(Number(Math.floor(this.netAmount.times(multiplier))));
        this.netAmount = this.netAmount.div(multiplier);
        this.netAmount = this.netAmount.toFixed(decimalSpaces);
      }
      this.withdrawalFee = !this.withdrawalFee ? 0 : this.withdrawalFee;
      this.withdrawalAmount = (new Big(this.netAmount).plus(this.withdrawalFee)).toFixed(decimalSpaces);
      this.fiatWithdrawal.controls.amount.markAsTouched();
      this.cryptoWithdrawal.controls.amount.markAsTouched();
    }
  }

  setDecimal(value: any) {
    if (typeof (value) !== 'undefined' && this.isNumber(value)) { // allow 0 as number
      const decimalSpaces = (this.coinCode.toLowerCase() === 'zar') ? 2 : 8;
      return (new Big(Number(value))).toFixed(decimalSpaces);
    }
  }

  amountChange() {
    if (!!this.withdrawalAmount) {
      const decimalSpaces = (this.coinCode.toLowerCase() === 'zar') ? 2 : 8;
      const ten = new Big(10);
      const multiplier = ten.pow(decimalSpaces);

      if (UtilHelper.checkDecimalSpacesLimit(this.withdrawalAmount, decimalSpaces)) {
        this.withdrawalAmount = new Big(Number(this.withdrawalAmount));
        this.withdrawalAmount = new Big(Number(Math.floor(this.withdrawalAmount.times(multiplier))));
        this.withdrawalAmount = this.withdrawalAmount.div(multiplier);
        this.withdrawalAmount = this.withdrawalAmount.toFixed(decimalSpaces);
      }
      this.withdrawalFee = !this.withdrawalFee ? 0 : this.withdrawalFee;
      this.netAmount = (new Big(this.withdrawalAmount).minus(this.withdrawalFee)).toFixed(decimalSpaces);
      this.fiatWithdrawal.controls.amount.markAsTouched();
      this.cryptoWithdrawal.controls.amount.markAsTouched();
      this.fiatWithdrawal.controls.netAmount.markAsTouched();
      this.cryptoWithdrawal.controls.netAmount.markAsTouched();

      if (this.isLightningNetwork()) {
        this.isLightningOverLimit(this.withdrawalAmount);
      }
    }
  }

  setPaymentIdValidators() {
    if (this.cryptoWithdrawal.controls.paymentIdNotRequired.value) {
      this.cryptoWithdrawal.controls.paymentID.setValue('');
      this.cryptoWithdrawal.controls.paymentID.disable();
      this.cryptoWithdrawal.controls.paymentID.setValidators(null);
    } else {
      if (!this.selectedFromBook) {
        this.cryptoWithdrawal.controls.paymentID.enable();
      }
      if (!!this.currencyInfo.payment_memo_label_validation) {
        this.cryptoWithdrawal.controls.paymentID.setValidators([
          Validators.required,
          Validators.pattern(this.currencyInfo.payment_memo_label_validation)
        ]);
      } else {
        this.cryptoWithdrawal.controls.paymentID.setValidators([
          Validators.required,
        ]);
      }
    }
    this.cryptoWithdrawal.controls.paymentID.updateValueAndValidity();
  }

  processWithdrawal() {
    if (this.coinType.toLowerCase() === 'fiat') {
      this.fiatWithdrawal.markAsTouched();
      this.fiatWithdrawal.controls.amount.markAsTouched();
      this.fiatWithdrawal.controls.netAmount.markAsTouched();
      this.fiatWithdrawal.controls.twofa.markAsTouched();
      this.fiatWithdrawal.controls.paymentID.markAsTouched();
      this.fiatWithdrawal.controls.bankSelection.markAsTouched();
      this.fiatWithdrawal.controls.fundPin.markAsTouched();
      if (this.currencyInfo.isFundpinEnabled === FundPinStatus.Enabled) {
        if (!this.currencyInfo.is2faenabled) {
          // disable twofa validation if fundpin is enabled and not 2fa
          this.fiatWithdrawal.controls.twofa.setErrors(null);
        }
      } else {
        // disable fundpin validation if fundpin is not enabled
        this.fiatWithdrawal.controls.fundPin.setErrors(null);
      }
      if (this.fiatWithdrawal.valid) {
        if (this.showNotification) {
          const dialogData: IConfirmDialogData = {
            coinCode: this.coinCode,
            bank:  this.selectedBank.accountNumber,
            amount: this.withdrawalAmount,
            fee: this.withdrawalFee,
            cancelType: DialogTypes.createWithdrawal,
          };
          if (this.coinNetworks.length > 0) {
            dialogData.networkName = this.selectedCoinNetwork.network_name;
          }
          const dialogRef = ConfirmDialogComponent.openDialog(this.dialog, dialogData);
          dialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
            if (!!result && result.result === 'accept') {
              this.requestFiatWithdraw();
            }
          });
        } else {
          this.requestFiatWithdraw();
        }
      }

    } else {
      this.cryptoWithdrawal.markAsTouched();
      this.cryptoWithdrawal.controls.amount.markAsTouched();
      this.cryptoWithdrawal.controls.netAmount.markAsTouched();
      this.cryptoWithdrawal.controls.twofa.markAsTouched();
      this.cryptoWithdrawal.controls.withdrawal.markAsTouched();
      this.cryptoWithdrawal.controls.fundPin.markAsTouched();
      this.cryptoWithdrawal.controls.paymentID.markAsTouched();
      this.cryptoWithdrawal.controls.coin_network.markAsTouched();
      this.cryptoWithdrawal.controls.invoiceAddress.markAsTouched();
      if (this.currencyInfo.isFundpinEnabled === FundPinStatus.Enabled) {
        if (!this.currencyInfo.is2faenabled) {
          // disable twofa validation if fundpin is enabled and not 2fa
          this.cryptoWithdrawal.controls.twofa.setErrors(null);
        }
      } else {
        // disable fundpin validation if fundpin is not enabled
        this.cryptoWithdrawal.controls.fundPin.setErrors(null);
      }
      if (!this.isLightningNetwork()) {
        this.validateAddress();
      }
      if (this.cryptoWithdrawal.valid) {
        if (this.showNotification) {
          const dialogData: IConfirmDialogData = {
            internal: this.isTransfer,
            coinCode: this.coinCode,
            address: this.withdrawalAddress,
            amount: this.withdrawalAmount,
            fee: this.withdrawalFee,
            cancelType: DialogTypes.createWithdrawal,
            networkName: this.currencyInfo.has_coin_networks === '1' ? this.selectedCoinNetwork.network_name : null
          };
          if (this.coinNetworks.length > 0) {
            dialogData.networkName = this.selectedCoinNetwork.network_name;
          }
          const dialogRef = ConfirmDialogComponent.openDialog(this.dialog, dialogData);
          dialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
            if (!!result && result.result === 'accept') {
              this.requestCryptoWithdraw();
            }
          });
        } else {
          this.requestCryptoWithdraw();
        }
      }
    }
  }

  requestFiatWithdraw() {
    const withdrawalFundpin = this.fiatWithdrawal.controls.fundPin.value;
    const data = {
      fauth: this.withdrawalTwofa,
      amount: this.withdrawalAmount,
      address: this.selectedBank.accountNumber,
      coin: this.coinCode,
      payment_id: this.fastClearing ? 'SAME_DAY_CLEARING' : this.paymentId,
      fundPin: withdrawalFundpin
    };

    this.balancesService.requestWithdrawal(data).pipe(take(1))
      .subscribe((resp: any) => {
        if (resp.response === 'success') {
          this.zarLimits.unverifiedRemainingLimit =
            this.withdrawalAmount > this.zarLimits.unverifiedRemainingLimit ? 0 :
              this.zarLimits.unverifiedRemainingLimit - this.withdrawalAmount;
          this.zarLimits.verifiedRemainingLimit =
            this.withdrawalAmount > this.zarLimits.verifiedRemainingLimit ? 0 :
              this.zarLimits.verifiedRemainingLimit - this.withdrawalAmount;

          this.withdrawalAmount = 0;
          this.withdrawalTwofa = '';
          this.paymentId = '';
          this.withdrawalFee = (this.selectedCoinNetwork && this.selectedCoinNetwork.withdraw_fee) ?
            Number(this.selectedCoinNetwork.withdraw_fee) : Number(this.currencyInfo.withdraw.withdrawFee);

          this.amountChange();

          const fee = this.withdrawalFee;
          const linkedAccount = this.selectedBank.name;

          this.fiatWithdrawal.reset();
          this.fiatWithdrawal.markAsPristine();

          this.fiatWithdrawal.controls.fee.setValue(fee);
          this.fiatWithdrawal.controls.bankSelection.setValue(linkedAccount);

          // this.store.showToastNotification('Withdrawal',
          //   this.translateService.translateResponse(resp.reason));
          this.currencyInfo.balance_available = resp.balanceData.balance_available;
          this.currencyInfo.withdraw.withdrawData = resp.withdrawalData;
          this.store.updateBalance(resp.balanceData);
          this.setupFormGroup();
          this.unselectAddressbook(true);
        } else {
          if (resp.reason === 'LOGIN_ACCOUNTDISABLED' || resp.reason === 'LOGIN_DISABLELOGIN') {
            this.sessionStorage.store('LOGGED_IN', '');
            this.closeModal();
          }
          SnackbarMessageComponent.openSnackBar(this.snackbar,
            this.translateService.translateResponse(resp.reason),
            30000
          );
        }
      }, () => { });
  }

  requestCryptoWithdraw() {
    const invoice = this.cryptoWithdrawal.controls.invoiceAddress.value;
    const withdrawalFundpin = this.cryptoWithdrawal.controls.fundPin.value;
    const data = {
      invoice,
      fauth: this.withdrawalTwofa,
      amount: this.withdrawalAmount,
      coin: this.coinCode,
      address: this.withdrawalAddress,
      payment_id: this.paymentId,
      fundPin: withdrawalFundpin,
      network_id: this.selectedCoinNetwork ? this.selectedCoinNetwork.id : null,
      isLightningNetwork: false,
      lightningReference: null,
      isLightningOverLimit: false
    };

    if (this.cryptoWithdrawal.controls.paymentIdNotRequired.value && this.coinCode === 'XRP') {
      data.payment_id = '0';
    }

    if (this.isLightningNetwork()) {
      data.address = invoice;
      data.lightningReference = this.invoiceReference;
      data.isLightningNetwork = true;
      data.isLightningOverLimit = !this.confirmLightning && this.isLightningOverLimit(this.withdrawalAmount);
    }

    this.balancesService.requestWithdrawal(data).pipe(take(1))
      .subscribe((resp: any) => {
        if (resp.response === 'success') {
          if (this.identifiedNewAddress && this.cryptoWithdrawal.controls.storeAddress.value) {
            this.openAddressBook(this.withdrawalAddress);
          }
          this.withdrawalAmount = 0;
          this.withdrawalTwofa = '';
          this.withdrawalAddress = '';
          this.paymentId = '';
          this.amountChange();
          this.identifiedNewAddress = false;

          this.selectedAddress = null;

          // reset selected cryptowithdrawal controls
          this.cryptoWithdrawal.controls.amount.reset();
          this.cryptoWithdrawal.controls.fee.reset();
          this.cryptoWithdrawal.controls.netAmount.reset();
          this.cryptoWithdrawal.controls.fundPin.reset();
          this.cryptoWithdrawal.controls.withdrawal.reset();
          this.cryptoWithdrawal.controls.twofa.reset();
          this.cryptoWithdrawal.controls.paymentID.reset();
          this.cryptoWithdrawal.controls.paymentIdNotRequired.reset();
          this.cryptoWithdrawal.controls.storeAddress.reset();
          this.cryptoWithdrawal.controls.invoiceAddress.reset();

          this.cryptoWithdrawal.markAsPristine();

          const fee = Number(this.currencyInfo.withdraw.withdrawFee);
          this.cryptoWithdrawal.controls.fee.setValue(fee);

          // this.store.showToastNotification('Withdrawal',
          // this.translateService.translateResponse(resp.reason));
          this.currencyInfo.balance_available = resp.balanceData.balance_available;
          this.currencyInfo.withdraw.withdrawData = resp.withdrawalData;
          this.store.updateBalance(resp.balanceData);
          this.setupFormGroup();

        } else {
          if (resp.reason === 'LOGIN_ACCOUNTDISABLED' || resp.reason === 'LOGIN_DISABLELOGIN') {
            this.sessionStorage.store('LOGGED_IN', '');
            this.closeModal();
          }
          SnackbarMessageComponent.openSnackBar(this.snackbar,
            this.translateService.translateResponse(resp.reason),
            30000
          );
        }
      },
      () => {}
    );
  }

  maxClick() {
    if (this.coinType.toLowerCase() === 'fiat') {
      this.setBankMaxLimits();
      this.withdrawalAmount = this.maxAmount;
    } else {
      const balance_available = Number(this.currencyInfo.balance_available);
      const max = this.currencyInfo.withdraw.withdrawLimits.max_withdraw === 'unlimited'
        ? balance_available : Number(this.currencyInfo.withdraw.withdrawLimits.max_withdraw);
      this.withdrawalAmount = max > balance_available ? balance_available : max;
    }
    this.amountChange();
  }

  calcMaxAmount() {
    const balance_available = !this.currencyInfo.balance_available ? 0 :
      Number(this.currencyInfo.balance_available);
    let max;
    if (this.currencyInfo.withdraw) {
      max = !this.currencyInfo.withdraw.withdrawLimits.max_withdraw ?
      (this.currencyInfo.withdraw.withdrawLimits.max_withdraw === 'unlimited' ? -1 : 0)
      : Number(this.currencyInfo.withdraw.withdrawLimits.max_withdraw);
    }
    this.maxAmount = this.isNumber(max) ? (max > balance_available ? balance_available : max).toFixed(8) :
      'unlimited';
  }

  getSupportUrl(): string {
    return environment.config.SUPPORT_URL;
  }

  getWithdrawalError() {
    if (this.linkedBankStatus === '0') {
      return this.i18n('Your bank account has not been verified.') +
      this.i18n(' To verify your bank account please click the link in the email that was sent to you.');
    } else {
      return this.i18n('You have reached the limits of your current verification level. Please verify your account') +
      ' <a routerLink="/profile" class="link" (click)="closeModal()">' + this.i18n('Here') + '</a>' +
      ' ' + this.i18n('or contact') + ' <a href="mailto://support.chainex.io">' + this.i18n('support') + '</a>.';
    }
  }

  toggleTwoFA() {
    const dialogRef = this.dialog.open(TwoFactorComponent, {
      width: '800px',
      data: { disable: false }
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
      if (dialogResult) { // dialog closed gracefully
        const dialogSuccess = dialogResult.result === 'success';
        if (dialogSuccess) {
          this.currencyInfo.is2faenabled = dialogResult.result === 'success';
        }
      } else { // dialog was force closed
        // check 2fa status in case it has changed
        this.getBalance();
      }
    });
  }

  toggleFundPin() {
    const dialogRef = this.dialog.open(FundPinComponent, {
      width: '600px',
      data: { enable: true }
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(dialogResult => {
      if (dialogResult) { // dialog closed gracefully
        const dialogSuccess = dialogResult.result === 'success';
        if (dialogSuccess) {
          this.currencyInfo.isFundpinPending = dialogResult.result === 'success';
        }
      } else { // dialog was force closed
        // check status in case it has changed
        this.getBalance();
      }
    });
  }

  resendFundPin() {
    this.settingsService.resendFundPin().subscribe((response) => {
      this.snackbar.open(
        this.translateService.translateResponse(response.reason),
        this.i18n('Close'), { duration: 4000 });
    }, (err) => {
      this.snackbar.open(
        'Error resending fund pin: ' + (err as Error).message,
        this.i18n('Close'), { duration: 4000 });
    });
  }

  userTyped(address: string) {
    this.validateAddress();
    const data: any = {
      address: address.trim(),
      coin: this.coinCode,
      isLightning: this.isLightningNetwork()
    };

    this.balancesService.checkTransferAddress(data).pipe(take(1)).subscribe((response) => {
      if (response.response === 'success') {
        this.isTransfer = response.data;
        this.setupFormGroup();
      } else if (response.reason) {
        this.cryptoWithdrawal.controls.withdrawal.setErrors({'ownAddress': true});
        this.cryptoWithdrawal.controls.withdrawal.markAsTouched();
      }
    });
  }

  closeModal() {
    if (!!this.parent) {
      this.parent.dialogRef.close();
    }
  }

  SetParent(parent: any) {
    this.parent = parent;
  }

  isNumber(n: any) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  isWithdrawalDisabled() {
    if (this.currencyInfo.ieo_coin === '1' && this.currencyInfo.ieo_coin_withdrawal !== '1') {
      const ieoCoins = this.sessionStorage.get('IEO_COINS');
      if (!ieoCoins || ieoCoins.indexOf(this.currencyInfo.id) === -1) {
        return true;
      }
    }
    return false;
  }

  validateAddress() {
    if (this.withdrawalAddress) {
      if (this.withdrawalAddress.indexOf(' ') !== -1) {
        this.cryptoWithdrawal.controls.withdrawal.setErrors({ whitespaceError: true });
      } else {
        const validEmail = !Validators.email(this.cryptoWithdrawal.controls.withdrawal);
        if (!(validEmail || (this.withdrawalAddress.match(this.regexTelephone)) ||
          (this.withdrawalAddress.match(this.regexWallet)))) {
            this.cryptoWithdrawal.controls.withdrawal.setErrors({ invalid: true });
        }
      }
    }
  }

  scanSuccessHandler(event: any) {
    const addyObj = event.split(':');
    let addy = '';
    (addyObj.length < 2) ? addy = addyObj[0] : addy = addyObj[1];

    if (this.isLightningNetwork()) {
      this.cryptoWithdrawal.controls.invoiceAddress.setValue(addy);
      this.invoiceChange();
    } else {
      this.cryptoWithdrawal.controls.withdrawal.setValue(addy);
    }
    this.scanQR = false;
  }

  bankAddressBookfilter(selectAddressBook: boolean = false): void {
    const value = this.fiatWithdrawal.controls.bankSelection.value.toLowerCase();
    if (!!value) {
      this.bankAddressBookfilteredOptions = this.bankAddressBookOptions.filter(bank =>
        bank.name.toLowerCase().indexOf(value) !== -1 ||
        bank.accountNumber.toLowerCase().indexOf(value) !== -1
      );
      if (this.bankAddressBookfilteredOptions.length <= 1 || selectAddressBook) {
        this.checkForTrustedBank();
      } else {
        this.updateBankAddressBookValidity(value);
      }
    } else {
      this.untrustedAddress = true;
      this.selectedBank = null;
      this.bankAddressBookfilteredOptions = this.bankAddressBookOptions;
    }
  }

  updateBankAddressBookValidity(value: string) {
    const bankValid = this.bankAddressBookOptions.filter(bank =>
      bank.accountNumber.toLocaleLowerCase() === value ||
      bank.name.toLocaleLowerCase() === value
    );

    if (!this.selectedBank && bankValid.length < 1) {
      this.fiatWithdrawal.controls.bankSelection.setErrors({'invalidBank': true});
    }
  }

  openAddressBook(address?: string) {
    const data: AddressBookSettings = {
      code: this.coinCode,
      address: address ? address : '',
      selectedCoinNetwork: this.selectedCoinNetwork ? this.selectedCoinNetwork.id : null,
      selectedNetworkName: this.selectedCoinNetwork ? this.selectedCoinNetwork.network_name : null,
      cryptoNoteAddress: {
        address: ''
      },
      coinNetworks: this.coinNetworks,
      is2faenabled: this.currencyInfo.is2faenabled,
      isFundpinEnabled: this.currencyInfo.isFundpinEnabled,
    };
    // Insert cryptonote address info
    if (!!this.currencyInfo.cryptonote_address) {
      data.cryptoNoteAddress.address = this.currencyInfo.cryptonote_address;
      data.cryptoNoteAddress.label = this.currencyInfo.payment_memo_label;
      data.cryptoNoteAddress.validation = this.currencyInfo.payment_memo_label_validation;
      if (address) {
        data.cryptoNoteAddress.paymentId = this.cryptoWithdrawal.controls.paymentID.value
        ? this.cryptoWithdrawal.controls.paymentID.value : null;
        data.cryptoNoteAddress.paymentIdNotRequired = this.cryptoWithdrawal.controls.paymentIdNotRequired.value;
      }
    }

    const dialogRef = this.dialog.open(AddressBookComponent, {
      width: '900px',
      data: data
    });

    dialogRef.afterClosed().subscribe((selected: DialogReturnData) => {
      /**
       * Order in which the fields is important, if we set the payment id after the address
       * we end up in a race condition for which address gets validated in `userTyped(address: string)`
       * */
      if (!!selected) {
        if (selected.paymentId) {
          this.selectedFromBook = true;
          this.cryptoWithdrawal.controls.paymentID.setValue(selected.paymentId);
          this.cryptoWithdrawal.controls.paymentID.disable();
          this.cryptoWithdrawal.controls.paymentIdNotRequired.setValue(false);
          this.cryptoWithdrawal.controls.paymentIdNotRequired.disable();
        } else if (selected.paymentId === '') {
          this.selectedFromBook = true;
          this.paymentId = '';
          this.cryptoWithdrawal.controls.paymentID.setValue('');
          this.cryptoWithdrawal.controls.paymentID.disable();
          this.cryptoWithdrawal.controls.paymentIdNotRequired.setValue(true);
          this.cryptoWithdrawal.controls.paymentIdNotRequired.disable();
        }

        if (selected.name) {
          this.cryptoWithdrawal.controls.withdrawal.setValue(selected.name);
          this.checkForTrustedAddress();
        }

        if (selected.coin_network) {
          const coinNetwork = this.coinNetworks?.find(network => network.id === selected.coin_network);
          if (coinNetwork) {
            this.selectedCoinNetwork = coinNetwork;
            this.applyCoinNetwork();
          }
        }
      }
    });
  }

  openBankAddressBook(addressCheck?: boolean) {
    const data: BankAddressBookSettings = {
      code: this.coinCode,
      verificationLevel: this.verificationLevel,
      addressCheck: addressCheck,
      is2faenabled: this.currencyInfo.is2faenabled,
      isFundpinEnabled: this.currencyInfo.isFundpinEnabled,
    };
    const dialogRef = this.dialog.open(BankAddressBookComponent, {
      width: '900px',
      data: data
    });

    dialogRef.afterClosed().subscribe((closedData: BankAddressBookCloseData) => {
      /**
       * On bank account deletion re obtain the balance, with the updated default bank account number
       * For zar getBalance automatically sets the default account
       */
      if (closedData && closedData.deleted) {
        this.getBalance();
      }
      // Set bank account from address book use address (Important to have this after the deleted check)
      if (closedData && closedData.name) {
        this.fiatWithdrawal.controls.bankSelection.setValue(closedData.name);
        this.bankAddressBookfilter(true);
      }
    });
  }

  checkForTrustedAddress() {
    let address = (this.cryptoWithdrawal.controls.withdrawal.value as string);
    let idxItem = this.addressBookOptions.findIndex((addy) => addy.name.toLowerCase() === address.toLowerCase());
    if (idxItem === -1) {
      idxItem = this.addressBookOptions.findIndex((addy) => addy.address === address);
    }
    if (idxItem > -1) {
      this.selectedAddress = this.addressBookOptions[idxItem];

      // apply new coin network if found
      if (this.selectedAddress.coin_network) {
        const coinNetwork = this.coinNetworks?.find(network => network.id === this.selectedAddress.coin_network);
        if (coinNetwork) {
          this.selectedCoinNetwork = coinNetwork;
          this.applyCoinNetwork();
        }
      }

      address = this.selectedAddress.address;
      // Handle setting of payment id values based on address found from input
      if (!!this.currencyInfo.cryptonote_address) {
        if (this.selectedAddress.paymentId !== PaymentIdLabel.Internal
          && this.selectedAddress.paymentId !== PaymentIdLabel.None) {
          this.cryptoWithdrawal.controls.paymentID.setValue(this.selectedAddress.paymentId);
          this.cryptoWithdrawal.controls.paymentID.disable();
        } else {
          this.cryptoWithdrawal.controls.paymentID.setValue('');
          this.cryptoWithdrawal.controls.paymentID.disable();
        }
        this.selectedFromBook = true;
      }
      this.identifiedNewAddress = false;
      this.cryptoWithdrawal.controls.storeAddress.setValue(false);
      if (this.selectedAddress.status === AddressStatus.Trusted) {
        this.cryptoWithdrawal.controls.twofa.clearValidators();
        this.cryptoWithdrawal.controls.fundPin.clearValidators();
        this.cryptoWithdrawal.controls.fundPin.setErrors(null);
        this.untrustedAddress = false;
      } else {
        this.cryptoWithdrawal.controls.twofa.setValidators([Validators.required]);
        this.untrustedAddress = true;
      }
    } else {
      this.selectedAddress = null;
      this.cryptoWithdrawal.controls.twofa.setValidators([Validators.required]);
      this.untrustedAddress = true;
      this.identifiedNewAddress = (address !== '');
      this.selectedFromBook = false;
      // Enable payment id controls
      if (!!this.currencyInfo.cryptonote_address) {
        this.cryptoWithdrawal.controls.paymentID.setValue('');
        this.cryptoWithdrawal.controls.paymentID.enable();
        this.cryptoWithdrawal.controls.paymentIdNotRequired.setValue(false);
        this.cryptoWithdrawal.controls.paymentIdNotRequired.enable();
      }
    }
    this.cryptoWithdrawal.controls.twofa.updateValueAndValidity();
    this.withdrawalAddress = address;
    /**
     * In this case we only need to check for an internal address based on
     * the address enetered if we dont have a payment id
     */
    if (!this.paymentId) {
      this.userTyped(address);
    }
    if (!!this.selectedAddress && this.cryptoWithdrawal.controls.withdrawal.hasError('whitespaceError')) {
      this.cryptoWithdrawal.controls.withdrawal.setErrors(null);
    }
  }

  checkForTrustedBank() {
    /**
    * Call this first so that tfa validators are set from the setupFormGroup function
    * we will then override the validators depending on the account trusted status
    */
    this.toggleFastClearing(false);
    const bankDetail = (this.fiatWithdrawal.controls.bankSelection.value as string);
    let idxItem = this.bankAddressBookOptions.findIndex((bank) =>
      bank.name.toLowerCase() === bankDetail.toLowerCase()
    );
    if (idxItem === -1) {
      idxItem = this.bankAddressBookOptions.findIndex((bank) => bank.accountNumber === bankDetail);
    }
    if (idxItem > -1) {
      this.calcMaxAmount();
      this.selectedBank = this.bankAddressBookOptions[idxItem];
      if (this.selectedBank.status === BankAddressStatus.Trusted) {
        this.fiatWithdrawal.controls.twofa.clearValidators();
        this.fiatWithdrawal.controls.fundPin.clearValidators();
        this.fiatWithdrawal.controls.fundPin.setErrors(null);
        this.untrustedAddress = false;
      } else {
        this.fiatWithdrawal.controls.twofa.setValidators([Validators.required]);
        this.fiatWithdrawal.controls.twofa.markAsUntouched();
        this.untrustedAddress = true;
      }
      // Set zar withdrawal limit validators
      this.setBankMaxLimits();
    } else {
      this.selectedBank = null;
      this.fiatWithdrawal.controls.bankSelection.setErrors({'invalidBank': true});
    }
    this.fiatWithdrawal.controls.twofa.updateValueAndValidity();
  }

  setBankMaxLimits() {
    if (this.maxAmount !== 'unlimited') {
      if (this.selectedBank?.verified === BankAddressVerified.Success) {
        this.maxAmount = this.zarLimits?.verifiedRemainingLimit;
      } else {
        this.maxAmount = this.zarLimits?.unverifiedRemainingLimit;
      }
      // Ensure max amount isnt greater than our available balance
      if (this.maxAmount > +this.currencyInfo.balance_available) {
        this.maxAmount = this.currencyInfo.balance_available;
      }

      this.fiatWithdrawal.controls.amount.setValidators([
        Validators.required,
        Validators.min(this.minAmount),
        Validators.max(this.maxAmount)
      ]);
    } else {
      this.fiatWithdrawal.controls.amount.setValidators([
        Validators.required,
        Validators.min(this.minAmount)
      ]);
    }
    this.fiatWithdrawal.controls.amount.updateValueAndValidity();
  }

  toggleFastClearing(forceValue?: boolean) {
    if (Object.prototype.toString.call(forceValue) === '[object Boolean]') {
      this.fastClearing = forceValue;
    } else {
      this.fastClearing = !this.fastClearing;
    }
    this.fiatWithdrawal.controls.fastClearing.setValue(this.fastClearing);

    if (this.fastClearing) {
      this.fastClearingTimer.subscribe();
    } else {
      this.fastClearingError = false;
    }
    this.setupFormGroup();
  }

  convertUtcHourToTime(utcHour: number) {
    const timeParts = (new Date((new Date).setUTCHours(utcHour, 0, 0, 0))).toTimeString().split(' ')[0].split(':');
    const startTime = `${timeParts[0]}:${timeParts[1]}`;
    return startTime;
  }

  videoHasPermission() {
    this.scanQR = !this.scanQR;

    if (this.scanQR) {
      // Check if we have permission to access the video input
      navigator.mediaDevices.getUserMedia({video: true}).then(() => {
        this.videoInput.hasPermission = true;
      }, (error) => {
        this.videoInput.hasPermission = false;
      });
    }
  }

  unselectAddressbook(faitWithdraw: boolean) {
    if (faitWithdraw) {
      this.selectedBank = null;
      this.fiatWithdrawal.controls.bankSelection.setValue('');
      this.bankAddressBookfilter();
    } else {
      this.selectedAddress = null;
      this.cryptoWithdrawal.controls.withdrawal.setValue('');
      this.checkForTrustedAddress();
      this.identifiedNewAddress = false;
    }
  }

  showPaymentId(): boolean {
    return (this.allowWithdrawal && !!this.currencyInfo.cryptonote_address &&
      (!this.isTransfer || (this.isTransfer && !this.isEmailorNumber)));
  }

  invoiceChange() {
    this.invoiceMemo = '';
    this.cryptoWithdrawal.controls.invoiceAddress.setErrors(null);
    if (!!this.cryptoWithdrawal.controls.invoiceAddress.value && this.cryptoWithdrawal.controls.invoiceAddress.value !== '') {
      try {
        let decodedPaymentRequest = Bolt11.decode(this.cryptoWithdrawal.controls.invoiceAddress.value);
        if (decodedPaymentRequest['sections']) {
          decodedPaymentRequest = decodedPaymentRequest['sections'];
          const amount = decodedPaymentRequest.filter((field) => field.name === 'amount');
          if (amount.length > 0 && !!(amount[0].value)) {
            this.withdrawalAmount = +(amount[0].value) / (10 ** 11); // msat to BTC
          }
          const memo = decodedPaymentRequest.filter((field) => field.name === 'description');
          if (memo.length > 0 && !!(memo[0].value)) {
            this.invoiceMemo = memo[0].value;
          }
          const payment_hash = decodedPaymentRequest.filter((field) => field.name === 'payment_hash');
          if (payment_hash.length > 0 && !!(payment_hash[0].value)) {
            this.invoiceReference = payment_hash[0].value;
            this.userTyped(this.invoiceReference); // Check for internal invoice payment
          }
        }
      } catch (e) {
        if ((e as Error).message === 'Not a proper lightning payment request') {
          this.cryptoWithdrawal.controls.invoiceAddress.setErrors({'invalidInvoice': true});
        }
      }
    } else {
      this.cryptoWithdrawal.controls.invoiceAddress.setErrors({'required': true});
    }
  }

  isLightningNetwork() {
    return this.selectedCoinNetwork?.network_name.toLowerCase() === 'lightning network';
  }

  isLightningOverLimit(amountInBtc: string = '0') {
    if (!this.confirmLightning && this.isLightningNetwork()) {
      const totalBtcAmount = new Big(
        Number(this.currencyInfo.withdraw.totalLightningWithdrawnToday)
      ).plus(+amountInBtc);

      const market = this.marketsService.getMarketbyCoinPair('BTC', 'ZAR');
      const zarEstimate = market ? Number(totalBtcAmount.toFixed(8)) * market.spreadPrice : 0;

      if (zarEstimate > this.lightningDailyLimit) {
        this.lightningOverDailyLimit = true;
        if (this.currencyInfo.is2faenabled) {
          this.cryptoWithdrawal.controls.twofa.setValidators([
            Validators.required
          ]);
        }
        if (this.currencyInfo.isFundpinEnabled) {
          this.cryptoWithdrawal.controls.fundPin.setValidators([
            Validators.required
          ]);
        }
      } else {
        this.lightningOverDailyLimit = false;
        // remove fundpin and 2fa validations
        this.cryptoWithdrawal.controls.twofa.setErrors(null);
        this.cryptoWithdrawal.controls.twofa.clearValidators();
        this.cryptoWithdrawal.controls.fundPin.setErrors(null);
        this.cryptoWithdrawal.controls.fundPin.clearValidators();
      }

      this.cryptoWithdrawal.controls.twofa.updateValueAndValidity();
      this.cryptoWithdrawal.controls.fundPin.updateValueAndValidity();
    }

    return this.lightningOverDailyLimit;
  }
}
